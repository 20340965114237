"use strict";

var detector = module.exports = {};

detector.isIE = function (version) {
  function isAnyIeVersion() {
    var agent = navigator.userAgent.toLowerCase();
    return agent.indexOf("msie") !== -1 || agent.indexOf("trident") !== -1 || agent.indexOf(" edge/") !== -1;
  }

  if (!isAnyIeVersion()) {
    return false;
  }

  if (!version) {
    return true;
  } //Shamelessly stolen from https://gist.github.com/padolsey/527683


  var ieVersion = function () {
    var undef,
        v = 3,
        div = document.createElement("div"),
        all = div.getElementsByTagName("i");

    do {
      div.innerHTML = "<!--[if gt IE " + ++v + "]><i></i><![endif]-->";
    } while (all[0]);

    return v > 4 ? v : undef;
  }();

  return version === ieVersion;
};

detector.isLegacyOpera = function () {
  return !!window.opera;
};