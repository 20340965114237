/*
HTML escaping is the same as React's
(on purpose.) Therefore, it has the following Copyright and Licensing:

Copyright 2013-2014, Facebook, Inc.
All rights reserved.

This source code is licensed under the BSD-style license found in the LICENSE
file in the root directory of React's source tree.
*/
import * as React from 'react';
import IntlMessageFormat from 'intl-messageformat';
import memoizeIntlConstructor from 'intl-format-cache';
import { invariant } from '@formatjs/intl-utils';
var ESCAPED_CHARS = {
  38: '&amp;',
  62: '&gt;',
  60: '&lt;',
  34: '&quot;',
  39: '&#x27;'
};
var UNSAFE_CHARS_REGEX = /[&><"']/g;
export function escape(str) {
  return ('' + str).replace(UNSAFE_CHARS_REGEX, function (match) {
    return ESCAPED_CHARS[match.charCodeAt(0)];
  });
}
export function filterProps(props, whitelist) {
  var defaults = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return whitelist.reduce(function (filtered, name) {
    if (name in props) {
      filtered[name] = props[name];
    } else if (name in defaults) {
      filtered[name] = defaults[name];
    }

    return filtered;
  }, {});
}
export function invariantIntlContext(intl) {
  invariant(intl, '[React Intl] Could not find required `intl` object. ' + '<IntlProvider> needs to exist in the component ancestry.');
}
export function createError(message, exception) {
  var eMsg = exception ? "\n".concat(exception.stack) : '';
  return "[React Intl] ".concat(message).concat(eMsg);
}
export function defaultErrorHandler(error) {
  if (process.env.NODE_ENV !== 'production') {
    console.error(error);
  }
}
export var DEFAULT_INTL_CONFIG = {
  formats: {},
  messages: {},
  timeZone: undefined,
  textComponent: React.Fragment,
  defaultLocale: 'en',
  defaultFormats: {},
  onError: defaultErrorHandler
};
export function createIntlCache() {
  return {
    dateTime: {},
    number: {},
    message: {},
    relativeTime: {},
    pluralRules: {},
    list: {},
    displayNames: {}
  };
}
/**
 * Create intl formatters and populate cache
 * @param cache explicit cache to prevent leaking memory
 */

export function createFormatters() {
  var cache = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : createIntlCache();
  var RelativeTimeFormat = Intl.RelativeTimeFormat;
  var ListFormat = Intl.ListFormat;
  var DisplayNames = Intl.DisplayNames;
  return {
    getDateTimeFormat: memoizeIntlConstructor(Intl.DateTimeFormat, cache.dateTime),
    getNumberFormat: memoizeIntlConstructor(Intl.NumberFormat, cache.number),
    getMessageFormat: memoizeIntlConstructor(IntlMessageFormat, cache.message),
    getRelativeTimeFormat: memoizeIntlConstructor(RelativeTimeFormat, cache.relativeTime),
    getPluralRules: memoizeIntlConstructor(Intl.PluralRules, cache.pluralRules),
    getListFormat: memoizeIntlConstructor(ListFormat, cache.list),
    getDisplayNames: memoizeIntlConstructor(DisplayNames, cache.displayNames)
  };
}
export function getNamedFormat(formats, type, name, onError) {
  var formatType = formats && formats[type];
  var format;

  if (formatType) {
    format = formatType[name];
  }

  if (format) {
    return format;
  }

  onError(createError("No ".concat(type, " format named: ").concat(name)));
}