"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("./core");

if (typeof Intl.PluralRules === 'undefined' || !Intl.PluralRules.polyfilled && new Intl.PluralRules('en', {
  minimumFractionDigits: 2
}).select(1) === 'one') {
  Object.defineProperty(Intl, 'PluralRules', {
    value: core_1.PluralRules,
    writable: true,
    enumerable: false,
    configurable: true
  });
}