var parse = require('../parse/index.js');
/**
 * @category Weekday Helpers
 * @summary Is the given date Saturday?
 *
 * @description
 * Is the given date Saturday?
 *
 * @param {Date|String|Number} date - the date to check
 * @returns {Boolean} the date is Saturday
 *
 * @example
 * // Is 27 September 2014 Saturday?
 * var result = isSaturday(new Date(2014, 8, 27))
 * //=> true
 */


function isSaturday(dirtyDate) {
  return parse(dirtyDate).getDay() === 6;
}

module.exports = isSaturday;