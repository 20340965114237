var encode = require("./lib/encode.js"),
    decode = require("./lib/decode.js");

exports.decode = function (data, level) {
  return (!level || level <= 0 ? decode.XML : decode.HTML)(data);
};

exports.decodeStrict = function (data, level) {
  return (!level || level <= 0 ? decode.XML : decode.HTMLStrict)(data);
};

exports.encode = function (data, level) {
  return (!level || level <= 0 ? encode.XML : encode.HTML)(data);
};

exports.encodeXML = encode.XML;
exports.encodeHTML4 = exports.encodeHTML5 = exports.encodeHTML = encode.HTML;
exports.decodeXML = exports.decodeXMLStrict = decode.XML;
exports.decodeHTML4 = exports.decodeHTML5 = exports.decodeHTML = decode.HTML;
exports.decodeHTML4Strict = exports.decodeHTML5Strict = exports.decodeHTMLStrict = decode.HTMLStrict;
exports.escape = encode.escape;