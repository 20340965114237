/* @generated */
// prettier-ignore
if (Intl.PluralRules && typeof Intl.PluralRules.__addLocaleData === 'function') {
  Intl.PluralRules.__addLocaleData({
    "data": {
      "sv": {
        "categories": {
          "cardinal": ["one", "other"],
          "ordinal": ["one", "other"]
        },
        "fn": function fn(n, ord) {
          var s = String(n).split('.'),
              v0 = !s[1],
              t0 = Number(s[0]) == n,
              n10 = t0 && s[0].slice(-1),
              n100 = t0 && s[0].slice(-2);
          if (ord) return (n10 == 1 || n10 == 2) && n100 != 11 && n100 != 12 ? 'one' : 'other';
          return n == 1 && v0 ? 'one' : 'other';
        }
      }
    },
    "aliases": {},
    "parentLocales": {},
    "availableLocales": ["sv"]
  });
}