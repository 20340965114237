var addQuarters = require('../add_quarters/index.js');
/**
 * @category Quarter Helpers
 * @summary Subtract the specified number of year quarters from the given date.
 *
 * @description
 * Subtract the specified number of year quarters from the given date.
 *
 * @param {Date|String|Number} date - the date to be changed
 * @param {Number} amount - the amount of quarters to be subtracted
 * @returns {Date} the new date with the quarters subtracted
 *
 * @example
 * // Subtract 3 quarters from 1 September 2014:
 * var result = subQuarters(new Date(2014, 8, 1), 3)
 * //=> Sun Dec 01 2013 00:00:00
 */


function subQuarters(dirtyDate, dirtyAmount) {
  var amount = Number(dirtyAmount);
  return addQuarters(dirtyDate, -amount);
}

module.exports = subQuarters;