var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

import { getCanonicalLocales } from './get-canonical-locales';
import { invariant } from './invariant';
import { toObject, getOption } from './polyfill-utils';
export function createResolveLocale(getDefaultLocale) {
  var lookupMatcher = createLookupMatcher(getDefaultLocale);
  var bestFitMatcher = createBestFitMatcher(getDefaultLocale);
  /**
   * https://tc39.es/ecma402/#sec-resolvelocale
   */

  return function resolveLocale(availableLocales, requestedLocales, options, relevantExtensionKeys, localeData) {
    var matcher = options.localeMatcher;
    var r;

    if (matcher === 'lookup') {
      r = lookupMatcher(availableLocales, requestedLocales);
    } else {
      r = bestFitMatcher(availableLocales, requestedLocales);
    }

    var foundLocale = r.locale;
    var result = {
      locale: '',
      dataLocale: foundLocale
    };
    var supportedExtension = '-u';

    for (var _i = 0, relevantExtensionKeys_1 = relevantExtensionKeys; _i < relevantExtensionKeys_1.length; _i++) {
      var key = relevantExtensionKeys_1[_i];
      var foundLocaleData = localeData[foundLocale];
      invariant(typeof foundLocaleData === 'object' && foundLocaleData !== null, "locale data " + key + " must be an object");
      var keyLocaleData = foundLocaleData[key];
      invariant(Array.isArray(keyLocaleData), "keyLocaleData for " + key + " must be an array");
      var value = keyLocaleData[0];
      invariant(typeof value === 'string' || value === null, 'value must be string or null');
      var supportedExtensionAddition = '';

      if (r.extension) {
        var requestedValue = unicodeExtensionValue(r.extension, key);

        if (requestedValue !== undefined) {
          if (requestedValue !== '') {
            if (~keyLocaleData.indexOf(requestedValue)) {
              value = requestedValue;
              supportedExtensionAddition = "-" + key + "-" + value;
            }
          } else if (~requestedValue.indexOf('true')) {
            value = 'true';
            supportedExtensionAddition = "-" + key;
          }
        }
      }

      if (key in options) {
        var optionsValue = options[key];
        invariant(typeof optionsValue === 'string' || typeof optionsValue === 'undefined' || optionsValue === null, 'optionsValue must be String, Undefined or Null');

        if (~keyLocaleData.indexOf(optionsValue)) {
          if (optionsValue !== value) {
            value = optionsValue;
            supportedExtensionAddition = '';
          }
        }
      }

      result[key] = value;
      supportedExtension += supportedExtensionAddition;
    }

    if (supportedExtension.length > 2) {
      var privateIndex = foundLocale.indexOf('-x-');

      if (privateIndex === -1) {
        foundLocale = foundLocale + supportedExtension;
      } else {
        var preExtension = foundLocale.slice(0, privateIndex);
        var postExtension = foundLocale.slice(privateIndex, foundLocale.length);
        foundLocale = preExtension + supportedExtension + postExtension;
      }

      foundLocale = getCanonicalLocales(foundLocale)[0];
    }

    result.locale = foundLocale;
    return result;
  };
}
/**
 * https://tc39.es/ecma402/#sec-unicodeextensionvalue
 * @param extension
 * @param key
 */

function unicodeExtensionValue(extension, key) {
  invariant(key.length === 2, 'key must have 2 elements');
  var size = extension.length;
  var searchValue = "-" + key + "-";
  var pos = extension.indexOf(searchValue);

  if (pos !== -1) {
    var start = pos + 4;
    var end = start;
    var k = start;
    var done = false;

    while (!done) {
      var e = extension.indexOf('-', k);
      var len = void 0;

      if (e === -1) {
        len = size - k;
      } else {
        len = e - k;
      }

      if (len === 2) {
        done = true;
      } else if (e === -1) {
        end = size;
        done = true;
      } else {
        end = e;
        k = e + 1;
      }
    }

    return extension.slice(start, end);
  }

  searchValue = "-" + key;
  pos = extension.indexOf(searchValue);

  if (pos !== -1 && pos + 3 === size) {
    return '';
  }

  return undefined;
}

var UNICODE_EXTENSION_SEQUENCE_REGEX = /-u(?:-[0-9a-z]{2,8})+/gi;
/**
 * https://tc39.es/ecma402/#sec-bestavailablelocale
 * @param availableLocales
 * @param locale
 */

function bestAvailableLocale(availableLocales, locale) {
  var candidate = locale;

  while (true) {
    if (~availableLocales.indexOf(candidate)) {
      return candidate;
    }

    var pos = candidate.lastIndexOf('-');

    if (!~pos) {
      return undefined;
    }

    if (pos >= 2 && candidate[pos - 2] === '-') {
      pos -= 2;
    }

    candidate = candidate.slice(0, pos);
  }
}

function createLookupMatcher(getDefaultLocale) {
  /**
   * https://tc39.es/ecma402/#sec-lookupmatcher
   */
  return function lookupMatcher(availableLocales, requestedLocales) {
    var result = {
      locale: ''
    };

    for (var _i = 0, requestedLocales_1 = requestedLocales; _i < requestedLocales_1.length; _i++) {
      var locale = requestedLocales_1[_i];
      var noExtensionLocale = locale.replace(UNICODE_EXTENSION_SEQUENCE_REGEX, '');
      var availableLocale = bestAvailableLocale(availableLocales, noExtensionLocale);

      if (availableLocale) {
        result.locale = availableLocale;

        if (locale !== noExtensionLocale) {
          result.extension = locale.slice(noExtensionLocale.length + 1, locale.length);
        }

        return result;
      }
    }

    result.locale = getDefaultLocale();
    return result;
  };
}

function createBestFitMatcher(getDefaultLocale) {
  return function bestFitMatcher(availableLocales, requestedLocales) {
    var result = {
      locale: ''
    };

    for (var _i = 0, requestedLocales_2 = requestedLocales; _i < requestedLocales_2.length; _i++) {
      var locale = requestedLocales_2[_i];
      var noExtensionLocale = locale.replace(UNICODE_EXTENSION_SEQUENCE_REGEX, '');
      var availableLocale = bestAvailableLocale(availableLocales, noExtensionLocale);

      if (availableLocale) {
        result.locale = availableLocale;

        if (locale !== noExtensionLocale) {
          result.extension = locale.slice(noExtensionLocale.length + 1, locale.length);
        }

        return result;
      }
    }

    result.locale = getDefaultLocale();
    return result;
  };
}

export function getLocaleHierarchy(locale, aliases, parentLocales) {
  var results = [locale];

  if (aliases[locale]) {
    locale = aliases[locale];
    results.push(locale);
  }

  var parentLocale = parentLocales[locale];

  if (parentLocale) {
    results.push(parentLocale);
  }

  var localeParts = locale.split('-');

  for (var i = localeParts.length; i > 1; i--) {
    results.push(localeParts.slice(0, i - 1).join('-'));
  }

  return results;
}

function lookupSupportedLocales(availableLocales, requestedLocales) {
  var subset = [];

  for (var _i = 0, requestedLocales_3 = requestedLocales; _i < requestedLocales_3.length; _i++) {
    var locale = requestedLocales_3[_i];
    var noExtensionLocale = locale.replace(UNICODE_EXTENSION_SEQUENCE_REGEX, '');
    var availableLocale = bestAvailableLocale(availableLocales, noExtensionLocale);

    if (availableLocale) {
      subset.push(availableLocale);
    }
  }

  return subset;
}

export function supportedLocales(availableLocales, requestedLocales, options) {
  var matcher = 'best fit';

  if (options !== undefined) {
    options = toObject(options);
    matcher = getOption(options, 'localeMatcher', 'string', ['lookup', 'best fit'], 'best fit');
  }

  if (matcher === 'best fit') {
    return lookupSupportedLocales(availableLocales, requestedLocales);
  }

  return lookupSupportedLocales(availableLocales, requestedLocales);
}

var MissingLocaleDataError =
/** @class */
function (_super) {
  __extends(MissingLocaleDataError, _super);

  function MissingLocaleDataError() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.type = 'MISSING_LOCALE_DATA';
    return _this;
  }

  return MissingLocaleDataError;
}(Error);

export function isMissingLocaleDataError(e) {
  return e.type === 'MISSING_LOCALE_DATA';
}
export function unpackData(locale, localeData,
/** By default shallow merge the dictionaries. */
reducer) {
  if (reducer === void 0) {
    reducer = function reducer(all, d) {
      return __assign(__assign({}, all), d);
    };
  }

  var localeHierarchy = getLocaleHierarchy(locale, localeData.aliases, localeData.parentLocales);
  var dataToMerge = localeHierarchy.map(function (l) {
    return localeData.data[l];
  }).filter(Boolean);

  if (!dataToMerge.length) {
    throw new MissingLocaleDataError("Missing locale data for \"" + locale + "\", lookup hierarchy: " + localeHierarchy.join(', '));
  }

  dataToMerge.reverse();
  return dataToMerge.reduce(reducer, {});
}