var Parser = require("./Parser.js");

var DomHandler = require("domhandler");

function defineProp(name, value) {
  delete module.exports[name];
  module.exports[name] = value;
  return value;
}

module.exports = {
  Parser: Parser,
  Tokenizer: require("./Tokenizer.js"),
  ElementType: require("domelementtype"),
  DomHandler: DomHandler,

  get FeedHandler() {
    return defineProp("FeedHandler", require("./FeedHandler.js"));
  },

  get Stream() {
    return defineProp("Stream", require("./Stream.js"));
  },

  get WritableStream() {
    return defineProp("WritableStream", require("./WritableStream.js"));
  },

  get ProxyHandler() {
    return defineProp("ProxyHandler", require("./ProxyHandler.js"));
  },

  get DomUtils() {
    return defineProp("DomUtils", require("domutils"));
  },

  get CollectingHandler() {
    return defineProp("CollectingHandler", require("./CollectingHandler.js"));
  },

  // For legacy support
  DefaultHandler: DomHandler,

  get RssHandler() {
    return defineProp("RssHandler", this.FeedHandler);
  },

  //helper methods
  parseDOM: function parseDOM(data, options) {
    var handler = new DomHandler(options);
    new Parser(handler, options).end(data);
    return handler.dom;
  },
  parseFeed: function parseFeed(feed, options) {
    var handler = new module.exports.FeedHandler(options);
    new Parser(handler, options).end(feed);
    return handler.dom;
  },
  createDomStream: function createDomStream(cb, options, elementCb) {
    var handler = new DomHandler(cb, options, elementCb);
    return new Parser(handler, options);
  },
  // List of all events that the parser emits
  EVENTS: {
    /* Format: eventname: number of arguments */
    attribute: 2,
    cdatastart: 0,
    cdataend: 0,
    text: 1,
    processinginstruction: 2,
    comment: 1,
    commentend: 0,
    closetag: 1,
    opentag: 2,
    opentagname: 1,
    error: 1,
    end: 0
  }
};