export default function dequal(foo, bar) {
  var ctor, len;
  if (foo === bar) return true;

  if (foo && bar && (ctor = foo.constructor) === bar.constructor) {
    if (ctor === Date) return foo.getTime() === bar.getTime();
    if (ctor === RegExp) return foo.toString() === bar.toString();

    if (ctor === Array && (len = foo.length) === bar.length) {
      while (len-- && dequal(foo[len], bar[len])) {
        ;
      }

      return len === -1;
    }

    if (ctor === Object) {
      if (Object.keys(foo).length !== Object.keys(bar).length) return false;

      for (len in foo) {
        if (!(len in bar) || !dequal(foo[len], bar[len])) return false;
      }

      return true;
    }
  }

  return foo !== foo && bar !== bar;
}