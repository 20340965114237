"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var MAP = exports.MAP = "__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED"; // export const SKELETON = `__SECRET_SKELETON_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;

var MARKER = exports.MARKER = "__SECRET_MARKER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";
var MARKER_WITH_LABEL = exports.MARKER_WITH_LABEL = "__SECRET_MARKER_WITH_LABEL_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";
var RECTANGLE = exports.RECTANGLE = "__SECRET_RECTANGLE_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";
var POLYLINE = exports.POLYLINE = "__SECRET_POLYLINE_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";
var POLYGON = exports.POLYGON = "__SECRET_POLYGON_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";
var CIRCLE = exports.CIRCLE = "__SECRET_CIRCLE_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";
var KML_LAYER = exports.KML_LAYER = "__SECRET_KML_LAYER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";
var DIRECTIONS_RENDERER = exports.DIRECTIONS_RENDERER = "__SECRET_DIRECTIONS_RENDERER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";
var HEATMAP_LAYER = exports.HEATMAP_LAYER = "__SECRET_HEATMAP_LAYER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";
var FUSION_TABLES_LAYER = exports.FUSION_TABLES_LAYER = "__SECRET_FUSION_TABLES_LAYER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";
var ANCHOR = exports.ANCHOR = "__SECRET_ANCHOR_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";
var INFO_WINDOW = exports.INFO_WINDOW = "__SECRET_INFO_WINDOW_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";
var OVERLAY_VIEW = exports.OVERLAY_VIEW = "__SECRET_OVERLAY_VIEW_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";
var GROUND_LAYER = exports.GROUND_LAYER = "__SECRET_GROUND_LAYER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";
var DRAWING_MANAGER = exports.DRAWING_MANAGER = "__SECRET_DRAWING_MANAGER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";
var SEARCH_BOX = exports.SEARCH_BOX = "__SECRET_SEARCH_BOX_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";
var MARKER_CLUSTERER = exports.MARKER_CLUSTERER = "__SECRET_MARKER_CLUSTERER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";
var INFO_BOX = exports.INFO_BOX = "__SECRET_INFO_BOX_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";
var TRAFFIC_LAYER = exports.TRAFFIC_LAYER = "__SECRET_TRAFFIC_LAYER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";
var STREET_VIEW_PANORAMA = exports.STREET_VIEW_PANORAMA = "__SECRET_STREET_VIEW_PANORAMA_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";
var BICYCLING_LAYER = exports.BICYCLING_LAYER = "__SECRET_BICYCLING_LAYER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED";