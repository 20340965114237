// DOM-Level-1-compliant structure
var NodePrototype = require('./node');

var ElementPrototype = module.exports = Object.create(NodePrototype);
var domLvl1 = {
  tagName: "name"
};
Object.keys(domLvl1).forEach(function (key) {
  var shorthand = domLvl1[key];
  Object.defineProperty(ElementPrototype, key, {
    get: function get() {
      return this[shorthand] || null;
    },
    set: function set(val) {
      this[shorthand] = val;
      return val;
    }
  });
});