module.exports = CollectingHandler;

function CollectingHandler(cbs) {
  this._cbs = cbs || {};
  this.events = [];
}

var EVENTS = require("./").EVENTS;

Object.keys(EVENTS).forEach(function (name) {
  if (EVENTS[name] === 0) {
    name = "on" + name;

    CollectingHandler.prototype[name] = function () {
      this.events.push([name]);
      if (this._cbs[name]) this._cbs[name]();
    };
  } else if (EVENTS[name] === 1) {
    name = "on" + name;

    CollectingHandler.prototype[name] = function (a) {
      this.events.push([name, a]);
      if (this._cbs[name]) this._cbs[name](a);
    };
  } else if (EVENTS[name] === 2) {
    name = "on" + name;

    CollectingHandler.prototype[name] = function (a, b) {
      this.events.push([name, a, b]);
      if (this._cbs[name]) this._cbs[name](a, b);
    };
  } else {
    throw Error("wrong number of arguments");
  }
});

CollectingHandler.prototype.onreset = function () {
  this.events = [];
  if (this._cbs.onreset) this._cbs.onreset();
};

CollectingHandler.prototype.restart = function () {
  if (this._cbs.onreset) this._cbs.onreset();

  for (var i = 0, len = this.events.length; i < len; i++) {
    if (this._cbs[this.events[i][0]]) {
      var num = this.events[i].length;

      if (num === 1) {
        this._cbs[this.events[i][0]]();
      } else if (num === 2) {
        this._cbs[this.events[i][0]](this.events[i][1]);
      } else {
        this._cbs[this.events[i][0]](this.events[i][1], this.events[i][2]);
      }
    }
  }
};