export var InternalSlotToken;

(function (InternalSlotToken) {
  // To prevent collision with {0} in CLDR
  InternalSlotToken["compactName"] = "compactName";
  InternalSlotToken["compactSymbol"] = "compactSymbol";
  InternalSlotToken["currencyCode"] = "currencyCode";
  InternalSlotToken["currencyName"] = "currencyName";
  InternalSlotToken["currencyNarrowSymbol"] = "currencyNarrowSymbol";
  InternalSlotToken["currencySymbol"] = "currencySymbol";
  InternalSlotToken["minusSign"] = "minusSign";
  InternalSlotToken["number"] = "number";
  InternalSlotToken["percentSign"] = "percentSign";
  InternalSlotToken["plusSign"] = "plusSign";
  InternalSlotToken["scientificExponent"] = "scientificExponent";
  InternalSlotToken["scientificSeparator"] = "scientificSeparator";
  InternalSlotToken["unitName"] = "unitName";
  InternalSlotToken["unitNarrowSymbol"] = "unitNarrowSymbol";
  InternalSlotToken["unitSymbol"] = "unitSymbol";
})(InternalSlotToken || (InternalSlotToken = {}));