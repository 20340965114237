module.exports = Stream;

var Parser = require("./Parser.js");

var WritableStream = require("readable-stream").Writable;

var StringDecoder = require("string_decoder").StringDecoder;

var Buffer = require("buffer").Buffer;

function Stream(cbs, options) {
  var parser = this._parser = new Parser(cbs, options);
  var decoder = this._decoder = new StringDecoder();
  WritableStream.call(this, {
    decodeStrings: false
  });
  this.once("finish", function () {
    parser.end(decoder.end());
  });
}

require("inherits")(Stream, WritableStream);

WritableStream.prototype._write = function (chunk, encoding, cb) {
  if (chunk instanceof Buffer) chunk = this._decoder.write(chunk);

  this._parser.write(chunk);

  cb();
};