var isSameMonth = require('../is_same_month/index.js');
/**
 * @category Month Helpers
 * @summary Is the given date in the same month as the current date?
 *
 * @description
 * Is the given date in the same month as the current date?
 *
 * @param {Date|String|Number} date - the date to check
 * @returns {Boolean} the date is in this month
 *
 * @example
 * // If today is 25 September 2014, is 15 September 2014 in this month?
 * var result = isThisMonth(new Date(2014, 8, 15))
 * //=> true
 */


function isThisMonth(dirtyDate) {
  return isSameMonth(new Date(), dirtyDate);
}

module.exports = isThisMonth;