module.exports = Stream;

var Parser = require("./WritableStream.js");

function Stream(options) {
  Parser.call(this, new Cbs(this), options);
}

require("inherits")(Stream, Parser);

Stream.prototype.readable = true;

function Cbs(scope) {
  this.scope = scope;
}

var EVENTS = require("../").EVENTS;

Object.keys(EVENTS).forEach(function (name) {
  if (EVENTS[name] === 0) {
    Cbs.prototype["on" + name] = function () {
      this.scope.emit(name);
    };
  } else if (EVENTS[name] === 1) {
    Cbs.prototype["on" + name] = function (a) {
      this.scope.emit(name, a);
    };
  } else if (EVENTS[name] === 2) {
    Cbs.prototype["on" + name] = function (a, b) {
      this.scope.emit(name, a, b);
    };
  } else {
    throw Error("wrong number of arguments!");
  }
});