var parse = require('../parse/index.js');
/**
 * @category Common Helpers
 * @summary Return a date from the array closest to the given date.
 *
 * @description
 * Return a date from the array closest to the given date.
 *
 * @param {Date|String|Number} dateToCompare - the date to compare with
 * @param {Date[]|String[]|Number[]} datesArray - the array to search
 * @returns {Date} the date from the array closest to the given date
 * @throws {TypeError} the second argument must be an instance of Array
 *
 * @example
 * // Which date is closer to 6 September 2015: 1 January 2000 or 1 January 2030?
 * var dateToCompare = new Date(2015, 8, 6)
 * var result = closestTo(dateToCompare, [
 *   new Date(2000, 0, 1),
 *   new Date(2030, 0, 1)
 * ])
 * //=> Tue Jan 01 2030 00:00:00
 */


function closestTo(dirtyDateToCompare, dirtyDatesArray) {
  if (!(dirtyDatesArray instanceof Array)) {
    throw new TypeError(toString.call(dirtyDatesArray) + ' is not an instance of Array');
  }

  var dateToCompare = parse(dirtyDateToCompare);
  var timeToCompare = dateToCompare.getTime();
  var result;
  var minDistance;
  dirtyDatesArray.forEach(function (dirtyDate) {
    var currentDate = parse(dirtyDate);
    var distance = Math.abs(timeToCompare - currentDate.getTime());

    if (result === undefined || distance < minDistance) {
      result = currentDate;
      minDistance = distance;
    }
  });
  return result;
}

module.exports = closestTo;