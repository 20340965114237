/* @generated */
// prettier-ignore  
export default {
  "aa-SAAHO": "ssy",
  "aam": "aas",
  "aar": "aa",
  "abk": "ab",
  "adp": "dz",
  "afr": "af",
  "aju": "jrb",
  "aka": "ak",
  "alb": "sq",
  "als": "sq",
  "amh": "am",
  "ara": "ar",
  "arb": "ar",
  "arg": "an",
  "arm": "hy",
  "art-lojban": "jbo",
  "asd": "snz",
  "asm": "as",
  "aue": "ktz",
  "ava": "av",
  "ave": "ae",
  "aym": "ay",
  "ayr": "ay",
  "ayx": "nun",
  "az-AZ": "az-Latn-AZ",
  "aze": "az",
  "azj": "az",
  "bak": "ba",
  "bam": "bm",
  "baq": "eu",
  "bcc": "bal",
  "bcl": "bik",
  "bel": "be",
  "ben": "bn",
  "bgm": "bcg",
  "bh": "bho",
  "bih": "bho",
  "bis": "bi",
  "bjd": "drl",
  "bod": "bo",
  "bos": "bs",
  "bre": "br",
  "bs-BA": "bs-Latn-BA",
  "bul": "bg",
  "bur": "my",
  "bxk": "luy",
  "bxr": "bua",
  "cat": "ca",
  "ccq": "rki",
  "cel-gaulish": "xtg-x-cel-gaulish",
  "ces": "cs",
  "cha": "ch",
  "che": "ce",
  "chi": "zh",
  "chu": "cu",
  "chv": "cv",
  "cjr": "mom",
  "cka": "cmr",
  "cld": "syr",
  "cmk": "xch",
  "cmn": "zh",
  "cnr": "sr-ME",
  "cor": "kw",
  "cos": "co",
  "coy": "pij",
  "cqu": "quh",
  "cre": "cr",
  "cwd": "cr",
  "cym": "cy",
  "cze": "cs",
  "dan": "da",
  "deu": "de",
  "dgo": "doi",
  "dhd": "mwr",
  "dik": "din",
  "diq": "zza",
  "dit": "dif",
  "div": "dv",
  "drh": "mn",
  "drw": "fa-af",
  "dut": "nl",
  "dzo": "dz",
  "ekk": "et",
  "ell": "el",
  "emk": "man",
  "eng": "en",
  "epo": "eo",
  "esk": "ik",
  "est": "et",
  "eus": "eu",
  "ewe": "ee",
  "fao": "fo",
  "fas": "fa",
  "fat": "ak",
  "fij": "fj",
  "fin": "fi",
  "fra": "fr",
  "fre": "fr",
  "fry": "fy",
  "fuc": "ff",
  "ful": "ff",
  "gav": "dev",
  "gaz": "om",
  "gbo": "grb",
  "geo": "ka",
  "ger": "de",
  "gfx": "vaj",
  "ggn": "gvr",
  "gla": "gd",
  "gle": "ga",
  "glg": "gl",
  "glv": "gv",
  "gno": "gon",
  "gre": "el",
  "grn": "gn",
  "gti": "nyc",
  "gug": "gn",
  "guj": "gu",
  "guv": "duz",
  "gya": "gba",
  "ha-Latn-GH": "ha-GH",
  "ha-Latn-NE": "ha-NE",
  "ha-Latn-NG": "ha-NG",
  "hat": "ht",
  "hau": "ha",
  "hbs": "sr-Latn",
  "hdn": "hai",
  "hea": "hmn",
  "heb": "he",
  "her": "hz",
  "him": "srx",
  "hin": "hi",
  "hmo": "ho",
  "hrr": "jal",
  "hrv": "hr",
  "hun": "hu",
  "hye": "hy",
  "i-ami": "ami",
  "i-bnn": "bnn",
  "i-hak": "hak",
  "i-klingon": "tlh",
  "i-lux": "lb",
  "i-navajo": "nv",
  "i-pwn": "pwn",
  "i-tao": "tao",
  "i-tay": "tay",
  "i-tsu": "tsu",
  "i-default": "en-x-i-default",
  "i-enochian": "und-x-i-enochian",
  "i-mingo": "see-x-i-mingo",
  "ibi": "opa",
  "ibo": "ig",
  "ice": "is",
  "ido": "io",
  "iii": "ii",
  "ike": "iu",
  "iku": "iu",
  "ile": "ie",
  "ilw": "gal",
  "in": "id",
  "ina": "ia",
  "ind": "id",
  "ipk": "ik",
  "isl": "is",
  "ita": "it",
  "iw": "he",
  "jav": "jv",
  "jeg": "oyb",
  "ji": "yi",
  "jpn": "ja",
  "jw": "jv",
  "kal": "kl",
  "kan": "kn",
  "kas": "ks",
  "kat": "ka",
  "kau": "kr",
  "kaz": "kk",
  "kgc": "tdf",
  "kgh": "kml",
  "khk": "mn",
  "khm": "km",
  "kik": "ki",
  "kin": "rw",
  "kir": "ky",
  "kk-Cyrl-KZ": "kk-KZ",
  "kmr": "ku",
  "knc": "kr",
  "kng": "kg",
  "knn": "kok",
  "koj": "kwv",
  "kom": "kv",
  "kon": "kg",
  "kor": "ko",
  "kpv": "kv",
  "krm": "bmf",
  "ks-Arab-IN": "ks-IN",
  "ktr": "dtp",
  "kua": "kj",
  "kur": "ku",
  "kvs": "gdj",
  "kwq": "yam",
  "kxe": "tvd",
  "ky-Cyrl-KG": "ky-KG",
  "kzj": "dtp",
  "kzt": "dtp",
  "lao": "lo",
  "lat": "la",
  "lav": "lv",
  "lbk": "bnc",
  "lii": "raq",
  "lim": "li",
  "lin": "ln",
  "lit": "lt",
  "llo": "ngt",
  "lmm": "rmx",
  "ltz": "lb",
  "lub": "lu",
  "lug": "lg",
  "lvs": "lv",
  "mac": "mk",
  "mah": "mh",
  "mal": "ml",
  "mao": "mi",
  "mar": "mr",
  "may": "ms",
  "meg": "cir",
  "mhr": "chm",
  "mkd": "mk",
  "mlg": "mg",
  "mlt": "mt",
  "mn-Cyrl-MN": "mn-MN",
  "mnk": "man",
  "mo": "ro",
  "mol": "ro",
  "mon": "mn",
  "mri": "mi",
  "ms-Latn-BN": "ms-BN",
  "ms-Latn-MY": "ms-MY",
  "ms-Latn-SG": "ms-SG",
  "msa": "ms",
  "mst": "mry",
  "mup": "raj",
  "mwj": "vaj",
  "mya": "my",
  "myd": "aog",
  "myt": "mry",
  "nad": "xny",
  "nau": "na",
  "nav": "nv",
  "nbl": "nr",
  "ncp": "kdz",
  "nde": "nd",
  "ndo": "ng",
  "nep": "ne",
  "nld": "nl",
  "nno": "nn",
  "nns": "nbr",
  "nnx": "ngv",
  "no": "nb",
  "no-bok": "nb",
  "no-BOKMAL": "nb",
  "no-nyn": "nn",
  "no-NYNORSK": "nn",
  "nob": "nb",
  "nor": "nb",
  "npi": "ne",
  "nts": "pij",
  "nya": "ny",
  "oci": "oc",
  "ojg": "oj",
  "oji": "oj",
  "ori": "or",
  "orm": "om",
  "ory": "or",
  "oss": "os",
  "oun": "vaj",
  "pa-IN": "pa-Guru-IN",
  "pa-PK": "pa-Arab-PK",
  "pan": "pa",
  "pbu": "ps",
  "pcr": "adx",
  "per": "fa",
  "pes": "fa",
  "pli": "pi",
  "plt": "mg",
  "pmc": "huw",
  "pmu": "phr",
  "pnb": "lah",
  "pol": "pl",
  "por": "pt",
  "ppa": "bfy",
  "ppr": "lcq",
  "prs": "fa-AF",
  "pry": "prt",
  "pus": "ps",
  "puz": "pub",
  "que": "qu",
  "quz": "qu",
  "rmy": "rom",
  "roh": "rm",
  "ron": "ro",
  "rum": "ro",
  "run": "rn",
  "rus": "ru",
  "sag": "sg",
  "san": "sa",
  "sca": "hle",
  "scc": "sr",
  "scr": "hr",
  "sgn-BE-FR": "sfb",
  "sgn-BE-NL": "vgt",
  "sgn-CH-DE": "sgg",
  "sh": "sr-Latn",
  "shi-MA": "shi-Tfng-MA",
  "sin": "si",
  "skk": "oyb",
  "slk": "sk",
  "slo": "sk",
  "slv": "sl",
  "sme": "se",
  "smo": "sm",
  "sna": "sn",
  "snd": "sd",
  "som": "so",
  "sot": "st",
  "spa": "es",
  "spy": "kln",
  "sqi": "sq",
  "sr-BA": "sr-Cyrl-BA",
  "sr-ME": "sr-Latn-ME",
  "sr-RS": "sr-Cyrl-RS",
  "sr-XK": "sr-Cyrl-XK",
  "src": "sc",
  "srd": "sc",
  "srp": "sr",
  "ssw": "ss",
  "sun": "su",
  "swa": "sw",
  "swc": "sw-CD",
  "swe": "sv",
  "swh": "sw",
  "tah": "ty",
  "tam": "ta",
  "tat": "tt",
  "tdu": "dtp",
  "tel": "te",
  "tgk": "tg",
  "tgl": "fil",
  "tha": "th",
  "thc": "tpo",
  "thx": "oyb",
  "tib": "bo",
  "tie": "ras",
  "tir": "ti",
  "tkk": "twm",
  "tl": "fil",
  "tlw": "weo",
  "tmp": "tyj",
  "tne": "kak",
  "tnf": "fa-af",
  "ton": "to",
  "tsf": "taj",
  "tsn": "tn",
  "tso": "ts",
  "ttq": "tmh",
  "tuk": "tk",
  "tur": "tr",
  "tw": "ak",
  "twi": "ak",
  "tzm-Latn-MA": "tzm-MA",
  "ug-Arab-CN": "ug-CN",
  "uig": "ug",
  "ukr": "uk",
  "umu": "del",
  "uok": "ema",
  "urd": "ur",
  "uz-AF": "uz-Arab-AF",
  "uz-UZ": "uz-Latn-UZ",
  "uzb": "uz",
  "uzn": "uz",
  "vai-LR": "vai-Vaii-LR",
  "ven": "ve",
  "vie": "vi",
  "vol": "vo",
  "wel": "cy",
  "wln": "wa",
  "wol": "wo",
  "xba": "cax",
  "xho": "xh",
  "xia": "acn",
  "xkh": "waw",
  "xpe": "kpe",
  "xsj": "suj",
  "xsl": "den",
  "ybd": "rki",
  "ydd": "yi",
  "yid": "yi",
  "yma": "lrr",
  "ymt": "mtm",
  "yor": "yo",
  "yos": "zom",
  "yue-CN": "yue-Hans-CN",
  "yue-HK": "yue-Hant-HK",
  "yuu": "yug",
  "zai": "zap",
  "zh-CN": "zh-Hans-CN",
  "zh-guoyu": "zh",
  "zh-hakka": "hak",
  "zh-HK": "zh-Hant-HK",
  "zh-min-nan": "nan",
  "zh-MO": "zh-Hant-MO",
  "zh-SG": "zh-Hans-SG",
  "zh-TW": "zh-Hant-TW",
  "zh-xiang": "hsn",
  "zh-min": "nan-x-zh-min",
  "zha": "za",
  "zho": "zh",
  "zsm": "ms",
  "zul": "zu",
  "zyb": "za"
};