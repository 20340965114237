/**
 * IE11-safe version of getCanonicalLocales since it's ES2016
 * @param locales locales
 */
export function getCanonicalLocales(locales) {
  // IE11
  var getCanonicalLocales = Intl.getCanonicalLocales;

  if (typeof getCanonicalLocales === 'function') {
    return getCanonicalLocales(locales);
  }

  return Intl.NumberFormat.supportedLocalesOf(locales || '');
}