var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var MS_PER_SECOND = 1e3;
var SECS_PER_MIN = 60;
var SECS_PER_HOUR = SECS_PER_MIN * 60;
var SECS_PER_DAY = SECS_PER_HOUR * 24;
var SECS_PER_WEEK = SECS_PER_DAY * 7;
export function selectUnit(from, to, thresholds) {
  if (to === void 0) {
    to = Date.now();
  }

  if (thresholds === void 0) {
    thresholds = {};
  }

  var resolvedThresholds = __assign(__assign({}, DEFAULT_THRESHOLDS), thresholds || {});

  var secs = (+from - +to) / MS_PER_SECOND;

  if (Math.abs(secs) < resolvedThresholds.second) {
    return {
      value: Math.round(secs),
      unit: 'second'
    };
  }

  var mins = secs / SECS_PER_MIN;

  if (Math.abs(mins) < resolvedThresholds.minute) {
    return {
      value: Math.round(mins),
      unit: 'minute'
    };
  }

  var hours = secs / SECS_PER_HOUR;

  if (Math.abs(hours) < resolvedThresholds.hour) {
    return {
      value: Math.round(hours),
      unit: 'hour'
    };
  }

  var days = secs / SECS_PER_DAY;

  if (Math.abs(days) < resolvedThresholds.day) {
    return {
      value: Math.round(days),
      unit: 'day'
    };
  }

  var fromDate = new Date(from);
  var toDate = new Date(to);
  var years = fromDate.getFullYear() - toDate.getFullYear();

  if (Math.round(Math.abs(years)) > 0) {
    return {
      value: Math.round(years),
      unit: 'year'
    };
  }

  var months = years * 12 + fromDate.getMonth() - toDate.getMonth();

  if (Math.round(Math.abs(months)) > 0) {
    return {
      value: Math.round(months),
      unit: 'month'
    };
  }

  var weeks = secs / SECS_PER_WEEK;
  return {
    value: Math.round(weeks),
    unit: 'week'
  };
}
export var DEFAULT_THRESHOLDS = {
  second: 45,
  minute: 45,
  hour: 22,
  day: 5
};