export { defineMessages } from '@formatjs/macro';
import { createFormattedComponent, createFormattedDateTimePartsComponent } from './components/createFormattedComponent';
export { default as injectIntl, Provider as RawIntlProvider, Context as IntlContext } from './components/injectIntl';
export { default as useIntl } from './components/useIntl';
export { default as IntlProvider, createIntl } from './components/provider'; // IMPORTANT: Explicit here to prevent api-extractor from outputing `import('./types').CustomFormatConfig`

export var FormattedDate = createFormattedComponent('formatDate');
export var FormattedTime = createFormattedComponent('formatTime');
export var FormattedNumber = createFormattedComponent('formatNumber');
export var FormattedList = createFormattedComponent('formatList');
export var FormattedDisplayName = createFormattedComponent('formatDisplayName');
export var FormattedDateParts = createFormattedDateTimePartsComponent('formatDate');
export var FormattedTimeParts = createFormattedDateTimePartsComponent('formatTime');
export { FormattedNumberParts } from './components/createFormattedComponent';
export { default as FormattedRelativeTime } from './components/relative';
export { default as FormattedPlural } from './components/plural';
export { default as FormattedMessage } from './components/message';
export { default as FormattedHTMLMessage } from './components/html-message';
export { createIntlCache } from './utils';