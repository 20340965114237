import { filterProps, createError } from '../utils';
var LIST_FORMAT_OPTIONS = ['localeMatcher', 'type', 'style'];
var now = Date.now();

function generateToken(i) {
  return "".concat(now, "_").concat(i, "_").concat(now);
}

export function formatList(_ref, getListFormat, values) {
  var locale = _ref.locale,
      onError = _ref.onError;
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var ListFormat = Intl.ListFormat;

  if (!ListFormat) {
    onError(createError("Intl.ListFormat is not available in this environment.\nTry polyfilling it using \"@formatjs/intl-listformat\"\n"));
  }

  var filteredOptions = filterProps(options, LIST_FORMAT_OPTIONS);

  try {
    var richValues = {};
    var serializedValues = values.map(function (v, i) {
      if (typeof v === 'object') {
        var id = generateToken(i);
        richValues[id] = v;
        return id;
      }

      return String(v);
    });

    if (!Object.keys(richValues).length) {
      return getListFormat(locale, filteredOptions).format(serializedValues);
    }

    var parts = getListFormat(locale, filteredOptions).formatToParts(serializedValues);
    return parts.reduce(function (all, el) {
      var val = el.value;

      if (richValues[val]) {
        all.push(richValues[val]);
      } else if (typeof all[all.length - 1] === 'string') {
        all[all.length - 1] += val;
      } else {
        all.push(val);
      }

      return all;
    }, []);
  } catch (e) {
    onError(createError('Error formatting list.', e));
  }

  return values;
}