var __spreadArrays = this && this.__spreadArrays || function () {
  for (var s = 0, i = 0, il = arguments.length; i < il; i++) {
    s += arguments[i].length;
  }

  for (var r = Array(s), k = 0, i = 0; i < il; i++) {
    for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++) {
      r[k] = a[j];
    }
  }

  return r;
};

import { isPluralElement, isLiteralElement, isSelectElement } from './types';
import { pegParse } from './parser';
var PLURAL_HASHTAG_REGEX = /(^|[^\\])#/g;
/**
 * Whether to convert `#` in plural rule options
 * to `{var, number}`
 * @param el AST Element
 * @param pluralStack current plural stack
 */

export function normalizeHashtagInPlural(els) {
  els.forEach(function (el) {
    // If we're encountering a plural el
    if (!isPluralElement(el) && !isSelectElement(el)) {
      return;
    } // Go down the options and search for # in any literal element


    Object.keys(el.options).forEach(function (id) {
      var _a;

      var opt = el.options[id]; // If we got a match, we have to split this
      // and inject a NumberElement in the middle

      var matchingLiteralElIndex = -1;
      var literalEl = undefined;

      for (var i = 0; i < opt.value.length; i++) {
        var el_1 = opt.value[i];

        if (isLiteralElement(el_1) && PLURAL_HASHTAG_REGEX.test(el_1.value)) {
          matchingLiteralElIndex = i;
          literalEl = el_1;
          break;
        }
      }

      if (literalEl) {
        var newValue = literalEl.value.replace(PLURAL_HASHTAG_REGEX, "$1{" + el.value + ", number}");
        var newEls = pegParse(newValue);

        (_a = opt.value).splice.apply(_a, __spreadArrays([matchingLiteralElIndex, 1], newEls));
      }

      normalizeHashtagInPlural(opt.value);
    });
  });
}