import { filterProps, createError } from '../utils';
var DISPLAY_NAMES_OPTONS = ['localeMatcher', 'style', 'type', 'fallback'];
export function formatDisplayName(_ref, getDisplayNames, value) {
  var locale = _ref.locale,
      onError = _ref.onError;
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var DisplayNames = Intl.DisplayNames;

  if (!DisplayNames) {
    onError(createError("Intl.DisplayNames is not available in this environment.\nTry polyfilling it using \"@formatjs/intl-displaynames\"\n"));
  }

  var filteredOptions = filterProps(options, DISPLAY_NAMES_OPTONS);

  try {
    return getDisplayNames(locale, filteredOptions).of(value);
  } catch (e) {
    onError(createError('Error formatting display name.', e));
  }
}