/* @generated */
// prettier-ignore
if (Intl.RelativeTimeFormat && typeof Intl.RelativeTimeFormat.__addLocaleData === 'function') {
  Intl.RelativeTimeFormat.__addLocaleData({
    "data": {
      "en-001": {
        "year-short": {
          "0": "this yr",
          "1": "next yr",
          "future": {
            "one": "in {0} yr",
            "other": "in {0} yr"
          },
          "past": {
            "one": "{0} yr ago",
            "other": "{0} yr ago"
          },
          "-1": "last yr"
        },
        "year-narrow": {
          "0": "this yr",
          "1": "next yr",
          "future": {
            "one": "in {0} yr",
            "other": "in {0} yr"
          },
          "past": {
            "one": "{0} yr ago",
            "other": "{0} yr ago"
          },
          "-1": "last yr"
        },
        "quarter-short": {
          "0": "this qtr.",
          "1": "next qtr.",
          "future": {
            "one": "in {0} qtr",
            "other": "in {0} qtr"
          },
          "past": {
            "one": "{0} qtr ago",
            "other": "{0} qtr ago"
          },
          "-1": "last qtr."
        },
        "quarter-narrow": {
          "0": "this qtr.",
          "1": "next qtr.",
          "future": {
            "one": "in {0} qtr",
            "other": "in {0} qtr"
          },
          "past": {
            "one": "{0} qtr ago",
            "other": "{0} qtr ago"
          },
          "-1": "last qtr."
        },
        "month-short": {
          "0": "this mo",
          "1": "next mo",
          "future": {
            "one": "in {0} mo",
            "other": "in {0} mo"
          },
          "past": {
            "one": "{0} mo ago",
            "other": "{0} mo ago"
          },
          "-1": "last mo"
        },
        "month-narrow": {
          "0": "this mo",
          "1": "next mo",
          "future": {
            "one": "in {0} mo",
            "other": "in {0} mo"
          },
          "past": {
            "one": "{0} mo ago",
            "other": "{0} mo ago"
          },
          "-1": "last mo"
        },
        "week-short": {
          "0": "this wk",
          "1": "next wk",
          "future": {
            "one": "in {0} wk",
            "other": "in {0} wk"
          },
          "past": {
            "one": "{0} wk ago",
            "other": "{0} wk ago"
          },
          "-1": "last wk"
        },
        "week-narrow": {
          "0": "this wk",
          "1": "next wk",
          "future": {
            "one": "in {0} wk",
            "other": "in {0} wk"
          },
          "past": {
            "one": "{0} wk ago",
            "other": "{0} wk ago"
          },
          "-1": "last wk"
        },
        "hour-short": {
          "0": "this hour",
          "future": {
            "one": "in {0} hr",
            "other": "in {0} hr"
          },
          "past": {
            "one": "{0} hr ago",
            "other": "{0} hr ago"
          }
        },
        "hour-narrow": {
          "0": "this hour",
          "future": {
            "one": "in {0} hr",
            "other": "in {0} hr"
          },
          "past": {
            "one": "{0} hr ago",
            "other": "{0} hr ago"
          }
        },
        "minute-short": {
          "0": "this minute",
          "future": {
            "one": "in {0} min",
            "other": "in {0} min"
          },
          "past": {
            "one": "{0} min ago",
            "other": "{0} min ago"
          }
        },
        "minute-narrow": {
          "0": "this minute",
          "future": {
            "one": "in {0} min",
            "other": "in {0} min"
          },
          "past": {
            "one": "{0} min ago",
            "other": "{0} min ago"
          }
        },
        "second-short": {
          "0": "now",
          "future": {
            "one": "in {0} sec",
            "other": "in {0} sec"
          },
          "past": {
            "one": "{0} sec ago",
            "other": "{0} sec ago"
          }
        },
        "second-narrow": {
          "0": "now",
          "future": {
            "one": "in {0} sec",
            "other": "in {0} sec"
          },
          "past": {
            "one": "{0} sec ago",
            "other": "{0} sec ago"
          }
        }
      },
      "en-150": {
        "year-short": {
          "0": "this yr",
          "1": "next yr",
          "future": {
            "one": "in {0} yr",
            "other": "in {0} yr"
          },
          "past": {
            "one": "{0} yr ago",
            "other": "{0} yr ago"
          },
          "-1": "last yr"
        },
        "year-narrow": {
          "0": "this yr",
          "1": "next yr",
          "future": {
            "one": "in {0} yr",
            "other": "in {0} yr"
          },
          "past": {
            "one": "{0} yr ago",
            "other": "{0} yr ago"
          },
          "-1": "last yr"
        },
        "quarter-short": {
          "0": "this qtr.",
          "1": "next qtr.",
          "future": {
            "one": "in {0} qtr",
            "other": "in {0} qtr"
          },
          "past": {
            "one": "{0} qtr ago",
            "other": "{0} qtr ago"
          },
          "-1": "last qtr."
        },
        "quarter-narrow": {
          "0": "this qtr.",
          "1": "next qtr.",
          "future": {
            "one": "in {0} qtr",
            "other": "in {0} qtr"
          },
          "past": {
            "one": "{0} qtr ago",
            "other": "{0} qtr ago"
          },
          "-1": "last qtr."
        },
        "month-short": {
          "0": "this mo",
          "1": "next mo",
          "future": {
            "one": "in {0} mo",
            "other": "in {0} mo"
          },
          "past": {
            "one": "{0} mo ago",
            "other": "{0} mo ago"
          },
          "-1": "last mo"
        },
        "month-narrow": {
          "0": "this mo",
          "1": "next mo",
          "future": {
            "one": "in {0} mo",
            "other": "in {0} mo"
          },
          "past": {
            "one": "{0} mo ago",
            "other": "{0} mo ago"
          },
          "-1": "last mo"
        },
        "week-short": {
          "0": "this wk",
          "1": "next wk",
          "future": {
            "one": "in {0} wk",
            "other": "in {0} wk"
          },
          "past": {
            "one": "{0} wk ago",
            "other": "{0} wk ago"
          },
          "-1": "last wk"
        },
        "week-narrow": {
          "0": "this wk",
          "1": "next wk",
          "future": {
            "one": "in {0} wk",
            "other": "in {0} wk"
          },
          "past": {
            "one": "{0} wk ago",
            "other": "{0} wk ago"
          },
          "-1": "last wk"
        },
        "hour-short": {
          "0": "this hour",
          "future": {
            "one": "in {0} hr",
            "other": "in {0} hr"
          },
          "past": {
            "one": "{0} hr ago",
            "other": "{0} hr ago"
          }
        },
        "hour-narrow": {
          "0": "this hour",
          "future": {
            "one": "in {0} hr",
            "other": "in {0} hr"
          },
          "past": {
            "one": "{0} hr ago",
            "other": "{0} hr ago"
          }
        },
        "minute-short": {
          "0": "this minute",
          "future": {
            "one": "in {0} min",
            "other": "in {0} min"
          },
          "past": {
            "one": "{0} min ago",
            "other": "{0} min ago"
          }
        },
        "minute-narrow": {
          "0": "this minute",
          "future": {
            "one": "in {0} min",
            "other": "in {0} min"
          },
          "past": {
            "one": "{0} min ago",
            "other": "{0} min ago"
          }
        },
        "second-short": {
          "0": "now",
          "future": {
            "one": "in {0} sec",
            "other": "in {0} sec"
          },
          "past": {
            "one": "{0} sec ago",
            "other": "{0} sec ago"
          }
        },
        "second-narrow": {
          "0": "now",
          "future": {
            "one": "in {0} sec",
            "other": "in {0} sec"
          },
          "past": {
            "one": "{0} sec ago",
            "other": "{0} sec ago"
          }
        }
      },
      "en-AU": {
        "year-short": {
          "0": "this yr",
          "1": "next yr",
          "future": {
            "one": "in {0} yr",
            "other": "in {0} yrs"
          },
          "past": {
            "one": "{0} yr ago",
            "other": "{0} yrs ago"
          },
          "-1": "last yr"
        },
        "year-narrow": {
          "0": "this yr",
          "1": "next yr",
          "future": {
            "one": "in {0} yr",
            "other": "in {0} yrs"
          },
          "past": {
            "one": "{0} yr ago",
            "other": "{0} yrs ago"
          },
          "-1": "last yr"
        },
        "quarter-short": {
          "0": "this qtr.",
          "1": "next qtr.",
          "future": {
            "one": "in {0} qtr",
            "other": "in {0} qtrs"
          },
          "past": {
            "one": "{0} qtr ago",
            "other": "{0} qtrs ago"
          },
          "-1": "last qtr."
        },
        "quarter-narrow": {
          "0": "this qtr.",
          "1": "next qtr.",
          "future": {
            "one": "in {0} qtr",
            "other": "in {0} qtrs"
          },
          "past": {
            "one": "in {0} qtr ago",
            "other": "{0} qtrs ago"
          },
          "-1": "last qtr."
        },
        "month-short": {
          "0": "this mo",
          "1": "next mo",
          "future": {
            "one": "in {0} mo.",
            "other": "in {0} mo."
          },
          "past": {
            "one": "{0} mo. ago",
            "other": "{0} mo. ago"
          },
          "-1": "last mo"
        },
        "month-narrow": {
          "0": "this mo",
          "1": "next mo",
          "future": {
            "one": "in {0} mo.",
            "other": "in {0} mo."
          },
          "past": {
            "one": "{0} mo. ago",
            "other": "{0} mo. ago"
          },
          "-1": "last mo"
        },
        "week-short": {
          "0": "this wk",
          "1": "next wk",
          "future": {
            "one": "in {0} wk",
            "other": "in {0} wks"
          },
          "past": {
            "one": "{0} wk ago",
            "other": "{0} wks ago"
          },
          "-1": "last wk"
        },
        "week-narrow": {
          "0": "this wk",
          "1": "next wk",
          "future": {
            "one": "in {0} wk",
            "other": "in {0} wks"
          },
          "past": {
            "one": "{0} wk ago",
            "other": "{0} wks ago"
          },
          "-1": "last wk"
        },
        "hour-short": {
          "0": "this hour",
          "future": {
            "one": "in {0} hr",
            "other": "in {0} hrs"
          },
          "past": {
            "one": "{0} hr ago",
            "other": "{0} hrs ago"
          }
        },
        "hour-narrow": {
          "0": "this hour",
          "future": {
            "one": "in {0} hr",
            "other": "in {0} hrs"
          },
          "past": {
            "one": "{0} hr ago",
            "other": "{0} hrs ago"
          }
        },
        "minute-short": {
          "0": "this minute",
          "future": {
            "one": "in {0} min.",
            "other": "in {0} mins"
          },
          "past": {
            "one": "{0} min. ago",
            "other": "{0} mins ago"
          }
        },
        "minute-narrow": {
          "0": "this minute",
          "future": {
            "one": "in {0} min.",
            "other": "in {0} mins"
          },
          "past": {
            "one": "{0} min. ago",
            "other": "{0} mins ago"
          }
        },
        "second-short": {
          "0": "now",
          "future": {
            "one": "in {0} sec.",
            "other": "in {0} secs"
          },
          "past": {
            "one": "{0} sec. ago",
            "other": "{0} secs ago"
          }
        },
        "second-narrow": {
          "0": "now",
          "future": {
            "one": "in {0} sec.",
            "other": "in {0} secs"
          },
          "past": {
            "one": "{0} sec. ago",
            "other": "{0} secs ago"
          }
        }
      },
      "en-CA": {
        "year-short": {
          "0": "this yr",
          "1": "next yr",
          "future": {
            "one": "in {0} yr.",
            "other": "in {0} yrs."
          },
          "past": {
            "one": "{0} yr. ago",
            "other": "{0} yrs. ago"
          },
          "-1": "last yr"
        },
        "year-narrow": {
          "0": "this yr",
          "1": "next yr",
          "future": {
            "one": "in {0} yr.",
            "other": "in {0} yrs."
          },
          "past": {
            "one": "{0} yr. ago",
            "other": "{0} yrs. ago"
          },
          "-1": "last yr"
        },
        "quarter-short": {
          "0": "this qtr.",
          "1": "next qtr.",
          "future": {
            "one": "in {0} qtr.",
            "other": "in {0} qtrs."
          },
          "past": {
            "one": "{0} qtr. ago",
            "other": "{0} qtrs. ago"
          },
          "-1": "last qtr."
        },
        "quarter-narrow": {
          "0": "this qtr.",
          "1": "next qtr.",
          "future": {
            "one": "in {0} qtr.",
            "other": "in {0} qtrs."
          },
          "past": {
            "one": "{0} qtr. ago",
            "other": "{0} qtrs. ago"
          },
          "-1": "last qtr."
        },
        "month-short": {
          "0": "this mo",
          "1": "next mo",
          "future": {
            "one": "in {0} mo.",
            "other": "in {0} mos."
          },
          "past": {
            "one": "{0} mo. ago",
            "other": "{0} mos. ago"
          },
          "-1": "last mo"
        },
        "month-narrow": {
          "0": "this mo",
          "1": "next mo",
          "future": {
            "one": "in {0} mo.",
            "other": "in {0} mos."
          },
          "past": {
            "one": "{0} mo. ago",
            "other": "{0} mos. ago"
          },
          "-1": "last mo"
        },
        "week-short": {
          "0": "this wk",
          "1": "next wk",
          "future": {
            "one": "in {0} wk.",
            "other": "in {0} wks."
          },
          "past": {
            "one": "{0} wk. ago",
            "other": "{0} wks. ago"
          },
          "-1": "last wk"
        },
        "week-narrow": {
          "0": "this wk",
          "1": "next wk",
          "future": {
            "one": "in {0} wk.",
            "other": "in {0} wks."
          },
          "past": {
            "one": "{0} wk. ago",
            "other": "{0} wks. ago"
          },
          "-1": "last wk"
        },
        "hour-short": {
          "0": "this hour",
          "future": {
            "one": "in {0} hr.",
            "other": "in {0} hrs."
          },
          "past": {
            "one": "{0} hr. ago",
            "other": "{0} hrs. ago"
          }
        },
        "hour-narrow": {
          "0": "this hour",
          "future": {
            "one": "in {0} hr.",
            "other": "in {0} hrs."
          },
          "past": {
            "one": "{0} hr. ago",
            "other": "{0} hrs. ago"
          }
        },
        "minute-short": {
          "0": "this minute",
          "future": {
            "one": "in {0} min.",
            "other": "in {0} mins."
          },
          "past": {
            "one": "{0} min. ago",
            "other": "{0} mins. ago"
          }
        },
        "minute-narrow": {
          "0": "this minute",
          "future": {
            "one": "in {0} min.",
            "other": "in {0} mins."
          },
          "past": {
            "one": "{0} min. ago",
            "other": "{0} mins. ago"
          }
        },
        "second-short": {
          "0": "now",
          "future": {
            "one": "in {0} sec.",
            "other": "in {0} secs."
          },
          "past": {
            "one": "{0} sec. ago",
            "other": "{0} secs. ago"
          }
        },
        "second-narrow": {
          "0": "now",
          "future": {
            "one": "in {0} sec.",
            "other": "in {0} secs."
          },
          "past": {
            "one": "{0} sec. ago",
            "other": "{0} secs. ago"
          }
        }
      },
      "en-SG": {
        "quarter-short": {
          "0": "this qtr",
          "1": "next qtr",
          "future": {
            "one": "in {0} qtr",
            "other": "in {0} qtrs"
          },
          "past": {
            "one": "{0} qtr ago",
            "other": "{0} qtrs ago"
          },
          "-1": "last qtr"
        },
        "quarter-narrow": {
          "0": "this qtr",
          "1": "next qtr",
          "future": {
            "one": "in {0} qtr",
            "other": "in {0} qtr"
          },
          "past": {
            "one": "{0} qtr ago",
            "other": "{0} qtr ago"
          },
          "-1": "last qtr"
        },
        "month-short": {
          "0": "this mth",
          "1": "next mth",
          "future": {
            "one": "in {0} mth",
            "other": "in {0} mth"
          },
          "past": {
            "one": "{0} mth ago",
            "other": "{0} mth ago"
          },
          "-1": "last mth"
        }
      },
      "en": {
        "nu": ["latn"],
        "year": {
          "0": "this year",
          "1": "next year",
          "future": {
            "one": "in {0} year",
            "other": "in {0} years"
          },
          "past": {
            "one": "{0} year ago",
            "other": "{0} years ago"
          },
          "-1": "last year"
        },
        "year-short": {
          "0": "this yr.",
          "1": "next yr.",
          "future": {
            "one": "in {0} yr.",
            "other": "in {0} yr."
          },
          "past": {
            "one": "{0} yr. ago",
            "other": "{0} yr. ago"
          },
          "-1": "last yr."
        },
        "year-narrow": {
          "0": "this yr.",
          "1": "next yr.",
          "future": {
            "one": "in {0} yr.",
            "other": "in {0} yr."
          },
          "past": {
            "one": "{0} yr. ago",
            "other": "{0} yr. ago"
          },
          "-1": "last yr."
        },
        "quarter": {
          "0": "this quarter",
          "1": "next quarter",
          "future": {
            "one": "in {0} quarter",
            "other": "in {0} quarters"
          },
          "past": {
            "one": "{0} quarter ago",
            "other": "{0} quarters ago"
          },
          "-1": "last quarter"
        },
        "quarter-short": {
          "0": "this qtr.",
          "1": "next qtr.",
          "future": {
            "one": "in {0} qtr.",
            "other": "in {0} qtrs."
          },
          "past": {
            "one": "{0} qtr. ago",
            "other": "{0} qtrs. ago"
          },
          "-1": "last qtr."
        },
        "quarter-narrow": {
          "0": "this qtr.",
          "1": "next qtr.",
          "future": {
            "one": "in {0} qtr.",
            "other": "in {0} qtrs."
          },
          "past": {
            "one": "{0} qtr. ago",
            "other": "{0} qtrs. ago"
          },
          "-1": "last qtr."
        },
        "month": {
          "0": "this month",
          "1": "next month",
          "future": {
            "one": "in {0} month",
            "other": "in {0} months"
          },
          "past": {
            "one": "{0} month ago",
            "other": "{0} months ago"
          },
          "-1": "last month"
        },
        "month-short": {
          "0": "this mo.",
          "1": "next mo.",
          "future": {
            "one": "in {0} mo.",
            "other": "in {0} mo."
          },
          "past": {
            "one": "{0} mo. ago",
            "other": "{0} mo. ago"
          },
          "-1": "last mo."
        },
        "month-narrow": {
          "0": "this mo.",
          "1": "next mo.",
          "future": {
            "one": "in {0} mo.",
            "other": "in {0} mo."
          },
          "past": {
            "one": "{0} mo. ago",
            "other": "{0} mo. ago"
          },
          "-1": "last mo."
        },
        "week": {
          "0": "this week",
          "1": "next week",
          "future": {
            "one": "in {0} week",
            "other": "in {0} weeks"
          },
          "past": {
            "one": "{0} week ago",
            "other": "{0} weeks ago"
          },
          "-1": "last week"
        },
        "week-short": {
          "0": "this wk.",
          "1": "next wk.",
          "future": {
            "one": "in {0} wk.",
            "other": "in {0} wk."
          },
          "past": {
            "one": "{0} wk. ago",
            "other": "{0} wk. ago"
          },
          "-1": "last wk."
        },
        "week-narrow": {
          "0": "this wk.",
          "1": "next wk.",
          "future": {
            "one": "in {0} wk.",
            "other": "in {0} wk."
          },
          "past": {
            "one": "{0} wk. ago",
            "other": "{0} wk. ago"
          },
          "-1": "last wk."
        },
        "day": {
          "0": "today",
          "1": "tomorrow",
          "future": {
            "one": "in {0} day",
            "other": "in {0} days"
          },
          "past": {
            "one": "{0} day ago",
            "other": "{0} days ago"
          },
          "-1": "yesterday"
        },
        "day-short": {
          "0": "today",
          "1": "tomorrow",
          "future": {
            "one": "in {0} day",
            "other": "in {0} days"
          },
          "past": {
            "one": "{0} day ago",
            "other": "{0} days ago"
          },
          "-1": "yesterday"
        },
        "day-narrow": {
          "0": "today",
          "1": "tomorrow",
          "future": {
            "one": "in {0} day",
            "other": "in {0} days"
          },
          "past": {
            "one": "{0} day ago",
            "other": "{0} days ago"
          },
          "-1": "yesterday"
        },
        "hour": {
          "0": "this hour",
          "future": {
            "one": "in {0} hour",
            "other": "in {0} hours"
          },
          "past": {
            "one": "{0} hour ago",
            "other": "{0} hours ago"
          }
        },
        "hour-short": {
          "0": "this hour",
          "future": {
            "one": "in {0} hr.",
            "other": "in {0} hr."
          },
          "past": {
            "one": "{0} hr. ago",
            "other": "{0} hr. ago"
          }
        },
        "hour-narrow": {
          "0": "this hour",
          "future": {
            "one": "in {0} hr.",
            "other": "in {0} hr."
          },
          "past": {
            "one": "{0} hr. ago",
            "other": "{0} hr. ago"
          }
        },
        "minute": {
          "0": "this minute",
          "future": {
            "one": "in {0} minute",
            "other": "in {0} minutes"
          },
          "past": {
            "one": "{0} minute ago",
            "other": "{0} minutes ago"
          }
        },
        "minute-short": {
          "0": "this minute",
          "future": {
            "one": "in {0} min.",
            "other": "in {0} min."
          },
          "past": {
            "one": "{0} min. ago",
            "other": "{0} min. ago"
          }
        },
        "minute-narrow": {
          "0": "this minute",
          "future": {
            "one": "in {0} min.",
            "other": "in {0} min."
          },
          "past": {
            "one": "{0} min. ago",
            "other": "{0} min. ago"
          }
        },
        "second": {
          "0": "now",
          "future": {
            "one": "in {0} second",
            "other": "in {0} seconds"
          },
          "past": {
            "one": "{0} second ago",
            "other": "{0} seconds ago"
          }
        },
        "second-short": {
          "0": "now",
          "future": {
            "one": "in {0} sec.",
            "other": "in {0} sec."
          },
          "past": {
            "one": "{0} sec. ago",
            "other": "{0} sec. ago"
          }
        },
        "second-narrow": {
          "0": "now",
          "future": {
            "one": "in {0} sec.",
            "other": "in {0} sec."
          },
          "past": {
            "one": "{0} sec. ago",
            "other": "{0} sec. ago"
          }
        }
      }
    },
    "availableLocales": ["en-001", "en-150", "en-AE", "en-AG", "en-AI", "en-AS", "en-AT", "en-AU", "en-BB", "en-BE", "en-BI", "en-BM", "en-BS", "en-BW", "en-BZ", "en-CA", "en-CC", "en-CH", "en-CK", "en-CM", "en-CX", "en-CY", "en-DE", "en-DG", "en-DK", "en-DM", "en-ER", "en-FI", "en-FJ", "en-FK", "en-FM", "en-GB", "en-GD", "en-GG", "en-GH", "en-GI", "en-GM", "en-GU", "en-GY", "en-HK", "en-IE", "en-IL", "en-IM", "en-IN", "en-IO", "en-JE", "en-JM", "en-KE", "en-KI", "en-KN", "en-KY", "en-LC", "en-LR", "en-LS", "en-MG", "en-MH", "en-MO", "en-MP", "en-MS", "en-MT", "en-MU", "en-MW", "en-MY", "en-NA", "en-NF", "en-NG", "en-NL", "en-NR", "en-NU", "en-NZ", "en-PG", "en-PH", "en-PK", "en-PN", "en-PR", "en-PW", "en-RW", "en-SB", "en-SC", "en-SD", "en-SE", "en-SG", "en-SH", "en-SI", "en-SL", "en-SS", "en-SX", "en-SZ", "en-TC", "en-TK", "en-TO", "en-TT", "en-TV", "en-TZ", "en-UG", "en-UM", "en-US-POSIX", "en-US", "en-VC", "en-VG", "en-VI", "en-VU", "en-WS", "en-ZA", "en-ZM", "en-ZW", "en"],
    "aliases": {},
    "parentLocales": {
      "en-150": "en-001",
      "en-AG": "en-001",
      "en-AI": "en-001",
      "en-AU": "en-001",
      "en-BB": "en-001",
      "en-BM": "en-001",
      "en-BS": "en-001",
      "en-BW": "en-001",
      "en-BZ": "en-001",
      "en-CA": "en-001",
      "en-CC": "en-001",
      "en-CK": "en-001",
      "en-CM": "en-001",
      "en-CX": "en-001",
      "en-CY": "en-001",
      "en-DG": "en-001",
      "en-DM": "en-001",
      "en-ER": "en-001",
      "en-FJ": "en-001",
      "en-FK": "en-001",
      "en-FM": "en-001",
      "en-GB": "en-001",
      "en-GD": "en-001",
      "en-GG": "en-001",
      "en-GH": "en-001",
      "en-GI": "en-001",
      "en-GM": "en-001",
      "en-GY": "en-001",
      "en-HK": "en-001",
      "en-IE": "en-001",
      "en-IL": "en-001",
      "en-IM": "en-001",
      "en-IN": "en-001",
      "en-IO": "en-001",
      "en-JE": "en-001",
      "en-JM": "en-001",
      "en-KE": "en-001",
      "en-KI": "en-001",
      "en-KN": "en-001",
      "en-KY": "en-001",
      "en-LC": "en-001",
      "en-LR": "en-001",
      "en-LS": "en-001",
      "en-MG": "en-001",
      "en-MO": "en-001",
      "en-MS": "en-001",
      "en-MT": "en-001",
      "en-MU": "en-001",
      "en-MW": "en-001",
      "en-MY": "en-001",
      "en-NA": "en-001",
      "en-NF": "en-001",
      "en-NG": "en-001",
      "en-NR": "en-001",
      "en-NU": "en-001",
      "en-NZ": "en-001",
      "en-PG": "en-001",
      "en-PH": "en-001",
      "en-PK": "en-001",
      "en-PN": "en-001",
      "en-PW": "en-001",
      "en-RW": "en-001",
      "en-SB": "en-001",
      "en-SC": "en-001",
      "en-SD": "en-001",
      "en-SG": "en-001",
      "en-SH": "en-001",
      "en-SL": "en-001",
      "en-SS": "en-001",
      "en-SX": "en-001",
      "en-SZ": "en-001",
      "en-TC": "en-001",
      "en-TK": "en-001",
      "en-TO": "en-001",
      "en-TT": "en-001",
      "en-TV": "en-001",
      "en-TZ": "en-001",
      "en-UG": "en-001",
      "en-VC": "en-001",
      "en-VG": "en-001",
      "en-VU": "en-001",
      "en-WS": "en-001",
      "en-ZA": "en-001",
      "en-ZM": "en-001",
      "en-ZW": "en-001",
      "en-AT": "en-150",
      "en-BE": "en-150",
      "en-CH": "en-150",
      "en-DE": "en-150",
      "en-DK": "en-150",
      "en-FI": "en-150",
      "en-NL": "en-150",
      "en-SE": "en-150",
      "en-SI": "en-150"
    }
  });
}