"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function _(msg) {
  return msg;
}

exports._ = _;

function defineMessages(msgs) {
  return msgs;
}

exports.defineMessages = defineMessages;