var parse = require('../parse/index.js');
/**
 * @category Millisecond Helpers
 * @summary Get the milliseconds of the given date.
 *
 * @description
 * Get the milliseconds of the given date.
 *
 * @param {Date|String|Number} date - the given date
 * @returns {Number} the milliseconds
 *
 * @example
 * // Get the milliseconds of 29 February 2012 11:45:05.123:
 * var result = getMilliseconds(new Date(2012, 1, 29, 11, 45, 5, 123))
 * //=> 123
 */


function getMilliseconds(dirtyDate) {
  var date = parse(dirtyDate);
  var milliseconds = date.getMilliseconds();
  return milliseconds;
}

module.exports = getMilliseconds;